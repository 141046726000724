a:link {
    color: (internal value);
    text-decoration: underline;
    cursor: auto;
  }
  
  a:visited {
    color: (internal value);
    text-decoration: underline;
    cursor: auto;
  }
  
  a:link:active {
    color: (internal value);
  }
  
  a:visited:active {
    color: (internal value);
  }
  
  address {
    display: block;
    font-style: italic;
  }
  
  article {
    display: block;
  }
  
  aside {
    display: block;
  }
  
  blockquote {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 40px;
    margin-right: 40px;
  }
  
  body {
    display: block;
    margin: 8px;
  }
  
  body:focus {
    outline: none;
  }
  
  button {
    /* None */
  }
  
  caption {
    display: table-caption;
    text-align: center;
  }
  
  cite {
    font-style: italic;
  }
  
  code {
    font-family: monospace;
  }
  
  dd {
    display: block;
    margin-left: 40px;
  }
  
  del {
    text-decoration: line-through;
  }
  
  details {
    display: block;
  }
  
  dfn {
    font-style: italic;
  }
  
  div {
    display: block;
  }
  
  dl {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
  }
  
  dt {
    display: block;
  }
  
  em {
    font-style: italic;
  }
  
  fieldset {
    display: block;
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 0.35em;
    padding-bottom: 0.625em;
    padding-left: 0.75em;
    padding-right: 0.75em;
    border: 2px groove (internal value);
  }
  
  figcaption {
    display: block;
  }
  
  figure {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 40px;
    margin-right: 40px;
  }
  
  footer {
    display: block;
  }
  
  form {
    display: block;
    margin-top: 0em;
  }
  
  h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  
  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  
  h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  
  h4 {
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  
  h5 {
    display: block;
    font-size: .83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  
  h6 {
    display: block;
    font-size: .67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  
  header {
    display: block;
  }
  
  hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
  }
  
  i {
    font-style: italic;
  }
  
  img {
    display: inline-block;
  }
  
  label {
    cursor: default;
  }
  
  legend {
    display: block;
    padding-left: 2px;
    padding-right: 2px;
    border: none;
  }
  
  li {
    display: list-item;
  }
  
  main {
    /* None */
  }
  
  menu {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }
  
  nav {
    display: block;
  }
  
  ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }
  
  p {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
  }
  
  pre {
    display: block;
    font-family: monospace;
    white-space: pre;
    margin: 1em 0;
  }
  
  q {
    display: inline;
  }
  
  q::before {
    content: open-quote;
  }
  
  q::after {
    content: close-quote;
  }
  
  rt {
    line-height: normal;
  }
  
  s {
    text-decoration: line-through;
  }
  
  samp {
    font-family: monospace;
  }
  
  section {
    display: block;
  }
  
  small {
    font-size: smaller;
  }
  
  strong {
    font-weight: bold;
  }
  
  sub {
    vertical-align: sub;
    font-size: smaller;
  }
  
  summary {
    display: block;
  }
  
  sup {
    vertical-align: super;
    font-size: smaller;
  }
  
  table {
    display: table;
    border-collapse: separate;
    border-spacing: 2px;
    border-color: gray;
  }
  
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
  
  td {
    display: table-cell;
    vertical-align: inherit;
  }
  
  tfoot {
    display: table-footer-group;
    vertical-align: middle;
    border-color: inherit;
  }
  
  th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: center;
  }
  
  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }
  
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  
  u {
    text-decoration: underline;
  }
  
  ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }
  
  var {
    font-style: italic;
  }
  abbr {
    /* None */
  }
  
  area {
    display: none;
  }
  
  audio {
    /* None */
  }
  
  b {
    font-weight: bold;
  }
  
  base {
    /* None */
  }
  
  bdi {
    /* None */
  }
  
  bdo {
    unicode-bidi: bidi-override;
  }
  
  canvas {
    /* None */
  }
  
  datalist {
    display: none;
  }
  
  dialog {
    /* None */
  }
  
  embed:focus {
    outline: none;
  }
  
  fieldset {
    display: block;
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 0.35em;
    padding-bottom: 0.625em;
    padding-left: 0.75em;
    padding-right: 0.75em;
    border: 2px groove (internal value);
  }
  
  figure {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 40px;
    margin-right: 40px;
  }
  
  head {
    display: none;
  }
  
  html {
    display: block;
  }
  
  html:focus {
    outline: none;
  }
  
  iframe:focus {
    outline: none;
  }
  
  iframe[seamless] {
    display: block;
  }
  
  input {
    /* None */
  }
  
  ins {
    text-decoration: underline;
  }
  
  kbd {
    font-family: monospace;
  }
  
  link {
    display: none;
  }
  
  map {
    display: inline;
  }
  
  mark {
    background-color: yellow;
    color: black;
  }
  
  menuitem {
    /* None */
  }
  
  meta {
    /* None */
  }
  
  meter {
    /* None */
  }
  
  noscript {
    /* None */
  }
  
  object:focus {
    outline: none;
  }
  
  optgroup {
    /* None */
  }
  
  option {
    /* None */
  }
  
  output {
    display: inline;
  }
  
  param {
    display: none;
  }
  
  picture {
    /* None */
  }
  
  progress {
    /* None */
  }
  
  script {
    display: none;
  }
  
  select {
    /* None */
  }
  
  source {
    /* None */
  }
  
  span {
    /* None */
  }
  
  strike {
    text-decoration: line-through;
  }
  
  style {
    display: none;
  }
  
  template {
    /* None */
  }
  
  textarea {
    /* None */
  }
  
  time {
    /* None */
  }
  
  title {
    display: none;
  }
  
  track {
    /* None */
  }
  
  video {
    /* None */
  }
  
  wbr {
    /* None */
  }
  