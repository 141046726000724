:root {
  --shadow: 0 5px 23px rgba(0, 0, 0, 0.2);
  --bg: #ff0000;
}
@import url("default.css");

*{
  box-sizing: border-box;
  padding: 0;
}
.container {
  max-width: 1450px;
  margin: 0 auto;
  z-index: 1;
  padding: 0 15px;
}
.pl-0 {
  padding-left: 0;
}
img {
  max-width: 100%;
  height: auto;
}
.body {
  /* width: max-content; */
}

.learn {
  background-color: aqua;
}

.d-flex {
  display: flex;
}
.d-flex-wrap {
  flex-wrap: wrap;
}
.my-10 {
  margin: 10px 0;
}
.my-20 {
  margin: 20px 0;
}
.mb-60 {
  margin-bottom: 60px;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-space-end {
  justify-content: end;
}
.align-item-center {
  align-items: center;
}
.text-align-center {
  text-align: center;
}
.gap-24 {
  gap: 24px;
}

.row {
  width: 100%;
  display: flex;
}
.request-detail-row {
  width: 100%;
  display: flex;
  margin: 80px 0;
}

.col-7 {
  width: 60%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.col-5 {
  width: 40%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.logo {
  width: 320px;
  height: 90px;
}
.header {
  padding: 40px 20px;
}
.girl {
  width: 100%;
  background-repeat: no-repeat;
  padding-bottom: 200px;
  /* background-position: center; */
  background-size: cover;
  display: grid;
  place-items: center end;
}

.header-blue-button {
  background-color: #03b2e9;
  padding: 8px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  margin: 0;
  cursor: pointer;
}

.footer-image {
  width: 100%;
  height: 650px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center end;
  margin-top: 100px;
}
.footer-image p {
  float: right;
  display: inline;
}
.img-text {
  width: fit-content;
}

.img-text-1 {
  font-size: 40px;
  color: #4e4e4e;
  text-align: center;
}

.img-text-2 {
  font-size: 60px;
  font-weight: bold;
  color: #022f77;
  text-align: center;
}

.problem {
  background-color: #434750;
}
/* .problem-text{
    display: flex;
    flex-direction: column;
    align-items: center;
} */
.problem-inner-contain {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}

.problem-header {
  color: #03b2e9;
  font-size: 40px;
  margin-top: 50px;
}
.problem-box-1 {
  text-align: center;
  padding: 30px;
}
.problem-body-text {
  color: white;
  font-size: 30px;
}

.problem-box {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  gap: 30px;
}

.problem-box-1-text {
  color: white;
  margin-top: 20px;
  font-size: 25px;
}

.solution-dashboard {
  margin-bottom: 80px;
}

.solution-box {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  gap: 30px;
}

.solution-header {
  color: #03b2e9;
  font-size: 40px;
  margin-top: 50px;
  font-weight: bold;
}

.solution-box-1 {
  text-align: center;
  padding: 30px;
}
.solution-box-1 p {
  font-size: 25px;
}

.solution-box-1 span {
  font-weight: bold;
}
.solution-text-1 {
  color: #022f77;
  font-size: 30px;
  margin: 10px 72px;
}

.sol-icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.msg-icon {
  width: 100px;
  height: 100px;
  /* filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(243deg)
    brightness(105%) contrast(101%); */
  margin: 0 auto;
}

.dashboardImage {
  width: auto;
  /* height: 400px; */
  margin: 0 auto;
  margin-top: -25px;
  box-shadow: var(--shadow);

  border-radius: 10px;
}
.dashboardImage2 {
  width: auto;
  /* height: 400px; */
  margin: 0 auto;
  margin-top: -25px;
  box-shadow: var(--shadow);
  border-radius: 10px;
}

.bg-line {
  position: relative;
}
.bg-line::after {
}
.img-contain {
  max-width: 600px;
  margin: 0 40px;
}
.img-contain h3 {
  font-size: 30px;
  color: #022f77;
}
.request-detail-text h3 {
  font-size: 30px;
  color: #022f77;
}
.request-detail-text {
  margin-left: 200px;
}

.img-contain p {
  margin-top: 40px;
}

.img-text-margin-bottom {
  margin: 30px;
}

.request-detail-text p {
  margin-top: 40px;
}

.sub-title::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #03b2e9;
  top: 50px;
  right: 0;
  z-index: -1;
  margin-top: 10px;
}

.request-detail-text {
  margin-left: 100px;
}

.footer-copyright {
  position: relative;
  background-color: #40434c;
  padding: 30px 0;
}
.footer-copyright p {
  text-align: center;
  color: white;
}

.img-shadow {
  /* background: linear-gradient(90deg, transparent, #fff); */
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(255, 255, 255, 0.7) 33%,
    rgba(255, 255, 255, 0.9) 44%,
    rgba(255, 255, 255, 1) 100%
  );
  height: 100%;
  width: 60%;
}
.img-inner-contain {
  max-width: 90%;
}
.billing-title {
  font-size: 42px;
  color: #003a98;
  text-align: right;
}
.img-inner-contain p {
  display: inline;
  float: right;
  text-align: center  ;
}

.img-margin-top {
  margin-top: 40px;
}

.report-dashboard {
  margin-bottom: 50px;
}

.end-image-text-2 {
  background-color: #03b2e9;
  padding: 10px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}

.iframe-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.youtube-container {
  overflow: hidden;
  max-width: 70%;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  /* Keep it the right aspect-ratio */
  aspect-ratio: 16/9;
  /* No clicking/hover effects */
  /* pointer-events: none; */
  border-radius: 10px;
}
.youtube-container > iframe {
  /* Extend it beyond the viewport... */
  width: 100%;
  height: 100%;
  /* ...and bring it back again */
}
@media (max-width: 1024px) {
  .img-text,
  .img-shadow {
    z-index: 1;
    position: relative;
    margin: 0 auto;
    margin-right: 0;
  }
  .img-text-1 {
    color: #fff;
  }
  .girl {
    padding: 90px 0;
    position: relative;
  }
  .girl::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, #000000, #8b8b8b);
    opacity: 0.6;
  }
}

.iframe-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.youtube-container {
	overflow: hidden;
  max-width: 70%;
	width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	/* Keep it the right aspect-ratio */
	aspect-ratio: 16/9;
	/* No clicking/hover effects */
	/* pointer-events: none; */
  border-radius: 10px;
	
	
}
.youtube-container > iframe {
  /* Extend it beyond the viewport... */
  width: 100%;
  height: 100%;
  /* ...and bring it back again */
}
@media (max-width: 1024px) {
  .img-text, .img-shadow{
    z-index: 1;
    position: relative;
    margin: 0 auto;
    margin-right: 0;
  }
  .img-text-1{
    color: #fff;
  }
  .girl {
    padding: 90px 0;
    position: relative;
  }
  .girl::after {
    content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom right,#000000,#8b8b8b);
        opacity: .6; 
  }
}

@media (max-width: 768px) {
  /* .d-flex {
    display: block;
  } */
  .problem-box,
  .solution-box {
    display: block;
  }
  .row {
    display: block;
  }
  .col-7,
  .col-5 {
    width: 100%;
    display: flex;
  }
  .header {
    display: block;
  }
  .header .gap-24 {
    margin-top: 24px;
  }
  .end-image-text-2 {
    padding: 5px 13px;
  }
  .dashboardImage2,
  .dashboardImage {
    margin-top: 0px;
  }
  .img-text,
  .img-shadow {
    z-index: 1;
    position: relative;
    margin: 0 auto;
    margin-right: 0;
  }
  .img-text-1 {
    color: #fff;
  }
  .img-shadow {
    width: 100%;
  }
  .girl {
    padding: 90px 0;
    position: relative;
  }
  .girl::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, #000000, #8b8b8b);
    opacity: 0.6;
  }
  .youtube-container {
    overflow: hidden;
    max-width: 90%;
    width: 100%;
  }
  .solution-text-1 {
    margin: 10px auto;
    font-size: 22px;
  }
  .img-inner-contain {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 100%;
    padding: 0 15px;
  }
  .img-contain {
    margin: 0;
  }
}
